<template>
  <div>
    <div v-for="item in list" :key="item.permId" class="text item">
      <el-link type="info" class="vg_cursor_hander" @click="jumpList(item.route, item.permId)">{{ item.moudlename }}</el-link>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { tranAPI } from '@/api/modules/tran';
import UrlEncode from '@assets/js/UrlEncode';
export default {
  name: 'RelationList',
  data() {
    return {
      list: [
        { permId: 126, moudlename: '销售合同', route: '/scon_list' },
        { permId: 127, moudlename: '采购合同', route: '/podr_list' },
        { permId: 132, moudlename: '采购发票', route: '/pinv_list' }
      ],
      tranForm: {}
    };
  },
  created() {
    this.getSconInfo();
  },
  methods: {
    // 跳转
    jumpList(val, val1) {
      if (val1 === 127) {
        this.jump(val, { perm_id: val1, scon_no: this.tranForm.scon_nos });
      } else if (val1 === 126) {
        this.jump(val, { perm_id: val1, scon_no: this.tranForm.scon_nos });
      } else if (val1 === 132) {
        this.jump(val, { perm_id: val1, tran_no: this.tranForm.tran_no });
      }
    },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(tranAPI.getTranById, { tran_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tranForm = res.data.data.form;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>
