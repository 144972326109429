<template>
  <div class="PartDetail">
    <el-table :data="deliCopyList" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" prop="scon_no"> </el-table-column>
      <el-table-column label="采购合同号" prop="podr_no"> </el-table-column>
      <el-table-column label="供应商简称" prop="supp_abbr"> </el-table-column>
      <!-- <el-table-column label="是否主产品" width="150" :formatter="tranPartMainFma">
      </el-table-column>
      <el-table-column label="是否报关" width="150" :formatter="tranPartMaincla">
      </el-table-column> -->
      <el-table-column label="起始箱号" width="150" prop="tran_part_ctns"> </el-table-column>
      <el-table-column label="结束箱号" width="150" prop="tran_part_ctne"> </el-table-column>
      <!-- <el-table-column label="原始订单号" width="150" prop="scon_cust_ono">
      </el-table-column>
      <el-table-column label="新订单号" width="150" prop="scon_cust_no">
      </el-table-column>
      <el-table-column label="客户货号" width="150" prop="prod_cust_no">
      </el-table-column>
      <el-table-column label="我司货号" width="150" prop="prod_no">
      </el-table-column>
      <el-table-column label="更改号" width="150" prop="prod_suffix">
      </el-table-column>
      <el-table-column label="部件名" width="150" prop="prod_partno">
      </el-table-column> -->
      <el-table-column label="中文名称" prop="prod_name"> </el-table-column>
      <!-- <el-table-column label="中文规格" width="150" prop="prod_spec">
      </el-table-column> -->
      <el-table-column label="海关编码" prop="prod_hscode"> </el-table-column>
      <!-- <el-table-column label="海关中文品名" width="150" prop="prod_hsname">
      </el-table-column>
      <el-table-column label="海关英文品名" width="150" prop="prod_ehsname">
      </el-table-column> -->
      <el-table-column label="订单数量" prop="prod_num"> </el-table-column>
      <el-table-column label="出运数量" prop="prod_ship"> </el-table-column>
      <!-- <el-table-column label="部件单位" width="150" prop="prod_unit">
      </el-table-column>
      <el-table-column label="成品销售价(美元)" width="150" prop="prod_usd">
      </el-table-column>
      <el-table-column label="成品折扣价(美元)" width="150" prop="prod_dusd">
      </el-table-column>
      <el-table-column label="金额(美元)" width="150" prop="prod_subtotal">
      </el-table-column>
      <el-table-column label="采购单价" width="150" prop="prod_price">
      </el-table-column>
      <el-table-column label="装箱数" width="150" prop="prod_qpc">
      </el-table-column>
      <el-table-column label="箱数" width="150" prop="prod_box_num">
      </el-table-column>
      <el-table-column label="包装单位" width="150" prop="prod_box_unit">
      </el-table-column>
      <el-table-column label="每箱毛重(KGS)" width="110" prop="prod_gweight">
      </el-table-column>
      <el-table-column label="总毛重(KGS)" width="150" prop="prod_tgweight">
      </el-table-column>
      <el-table-column label="每箱净重(KGS)" width="110" prop="prod_nweight">
      </el-table-column>
      <el-table-column label="总净重(KGS)" width="150" prop="prod_tnweight">
      </el-table-column>
      <el-table-column label="长度(CM)" width="150" prop="prod_boxl">
      </el-table-column>
      <el-table-column label="宽度(CM)" width="150" prop="prod_boxw">
      </el-table-column>
      <el-table-column label="高度(CM)" width="150" prop="prod_boxh">
      </el-table-column>
      <el-table-column label="每箱体积(CBM)" width="110" prop="prod_vol">
      </el-table-column>
      <el-table-column label="总体积(CBM)" width="150" prop="prod_tvol">
      </el-table-column>
      <el-table-column label="退税率" width="150" prop="prod_hsoutrate">
      </el-table-column>
      <el-table-column label="增值税率" width="150" prop="prod_hsinrate">
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import TranPartImport from './TranPartImport.vue';
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { useSuppMixin, useCustMixin, useStffMixin, useDeptMixin } from '@/assets/js/mixin/initMixin';
import { BigNumber } from 'bignumber.js';
export default {
  name: 'PartDetail',
  props: {
    deliCopyList: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    },
    companyList: {
      type: Array,
      required: true
    }
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (Number(value) < 5) {
        return callback(new Error('必须大于10'));
      } else {
        callback();
      }
    };
    return {
      selectionsList: [],
      rulesCopy: checkAge
    };
  },
  components: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    tranPartMainFma(row) {
      console.log('12313', row);
      if (row.tran_part_main === '1') {
        return '是';
      } else if (row.tran_part_main === '0') {
        return '否';
      }
    },
    tranPartMaincla(row) {
      if (row.tran_part_claim === '1') {
        return '是';
      } else if (row.tran_part_claim === '0') {
        return '否';
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
