<template>
  <div class="tranEditMain" v-loading="loadingFlag">
    <el-row v-sticky class="vd_edhea vg_button_group tran_header" style="padding-top: 16px; background: white">
      <el-col>
        <editHeader :isShow="isShow" :btn="btn" :strForm="tranForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('tranForm')" />
        <div v-if="isShow" class="vd_export" style="padding-top: 16px">
          <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
          <span v-if="!tranForm.autoInc" class="vg_ml_8">
            <el-button size="small" type="warning" @click="deliDialog()">生成进仓单</el-button>
            <el-button size="small" type="info" @click="PrintTotal">打印下载报关相关单据</el-button>
            <!--          <el-button size="small" type="info" @click="dialogVisibleGroup = true">查看报关汇总明细</el-button>-->
            <el-button size="small" type="warning" @click="exportTableO">生成验货表</el-button>
            <el-button size="small" type="warning" @click="exportTableT">生成VENDOR</el-button>
            <el-button size="small" type="warning" @click="tuoDialog = true">生成拖箱单</el-button>
          </span>
        </div>
        <div class="vd_export2 vg_mr_8" style="padding-top: 16px">
          <span style="margin-right: 2px">发票号:</span>
          <el-input size="mini" style="width: 200px; margin-right: 20px" v-model="tranForm.tran_no" :disabled="isShow" show-word-limit maxlength="20">
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form :model="tranForm" ref="tranForm" label-width="120px" size="mini" :rules="tranFormRules" :disabled="isShow">
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="用友对接状态" prop="yongYouStatus" v-if="yongYouStatus === '已抓取'">
                <el-input v-model="yongYouStatus" disabled size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-input disabled v-model="tranForm.cust_abbr" placeholder="请输入客户简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="最终客户" prop="cust_cabbr">
                <el-input v-model="tranForm.cust_cabbr" disabled placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司抬头" prop="cptt_aid">
                <el-select @change="cpttChange" size="small" filterable v-model="tranForm.cptt_aid" placeholder="请选择公司抬头">
                  <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="8">
              <el-form-item label="出运经办人" prop="tran_stff_id">
                <el-select filterable v-model="tranForm.tran_stff_id" placeholder="请选择出运经办人">
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="销售合同号" prop="scon_nos">
                <el-input disabled v-model="tranForm.scon_nos" placeholder="请输入销售合同号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户订单号" prop="scon_cust_nos">
                <el-input disabled v-model="tranForm.scon_cust_nos" placeholder="暂无客户订单号">
                  <template slot="append">
                    <el-link :disabled="isShow" type="primary" class="vg_pointer" @click="sconCustSelect">选择</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="货代简称" prop="inst_abbr">
                <el-input disabled v-model="tranForm.inst_abbr" placeholder="暂无货代简称">
                  <template slot="append">
                    <el-link :disabled="isShow" type="primary" class="vg_pointer" @click="dialogInstVisible2 = true"> 选择 </el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="付款方式" prop="cust_payway">
                <el-select filterable v-model="tranForm.cust_payway" placeholder="请选择付款方式">
                  <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="价格条款" prop="cust_paylaw">
                <el-select filterable v-model="tranForm.cust_paylaw" placeholder="请选择价格条款">
                  <el-option v-for="item in custPaylawList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="发票日期" prop="tran_ivdate">
                <el-date-picker v-model="tranForm.tran_ivdate" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="运输方式" prop="cust_shipway">
                <el-select filterable v-model="tranForm.cust_shipway" placeholder="请选择运输方式">
                  <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="运费支付方式" prop="cust_shippay">
                <el-select filterable v-model="tranForm.cust_shippay" placeholder="请选择运费支付方式">
                  <el-option v-for="item in custShippayList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="应收日期" prop="tran_ppdate">
                <el-date-picker v-model="tranForm.tran_ppdate" placeholder="选择日期" type="date"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="出运金额" prop="tran_prtotal">
                <el-input disabled v-model="tranForm.tran_prtotal" placeholder="暂无出运金额">
                  <template slot="append">美元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="币种" prop="cust_currency">
                <el-select filterable v-model="tranForm.cust_currency" placeholder="请选择币种">
                  <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="费用金额" prop="tran_fetotal">
                <el-input disabled v-model="tranForm.tran_fetotal" placeholder="暂无费用金额">
                  <template slot="append">美元</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="人民币金额" prop="tran_rmbtotal">
                <el-input disabled v-model="tranForm.tran_rmbtotal" placeholder="暂无费用金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="汇率" prop="tran_usdrate">
                <el-input
                  v-model="tranForm.tran_usdrate"
                  placeholder="暂无汇率"
                  maxlength="9"
                  show-word-limit
                  @input="tranForm.tran_usdrate = helper.keepTNum1(tranForm.tran_usdrate)"
                  @change="tranForm.tran_usdrate = new BigNumber(tranForm.tran_usdrate).toFixed(6)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="航名航班信息" prop="tran_flight">
                <el-select clearable filterable v-model="tranForm.tran_flight" placeholder="请选择">
                  <el-option v-for="item in ['CONTAINER NUMBER/SEAL NUMBER', 'FLIGHT NUMBER']" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="船名/航次" prop="tran_usdrate">
                <el-input v-model="tranForm.tran_shipName_voyage" placeholder="暂无船名/航次" maxlength="20" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="起运港" prop="tran_sport">
                <el-select filterable v-model="tranForm.tran_sport" placeholder="请选择起运港">
                  <el-option v-for="item in portOfShipmentList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="目的港" prop="cust_dport">
                <el-select filterable v-model="tranForm.cust_dport" placeholder="请选择目的港" @change="custDportChange">
                  <el-option v-for="item in TranSportList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="目的国" prop="cust_edcountry">
                <el-select filterable v-model="tranForm.cust_edcountry" placeholder="请选择目的国">
                  <el-option v-for="item in custDcountryList" :key="item.id" :label="item.param2" :value="item.param2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="装运日期" prop="tran_spdate">
                <!--@change="tranSpdateChange"-->
                <el-date-picker v-model="tranForm.tran_spdate" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="离港日" prop="tran_dpdate">
                <el-date-picker v-model="tranForm.tran_dpdate" type="date" placeholder="选择日期" @change="custDportChange"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="到港日" prop="tran_eta">
                <el-date-picker v-model="tranForm.tran_eta" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="集装箱号" prop="tran_containo">
                <el-input v-model="tranForm.tran_containo" placeholder="请输入集装箱号" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="集装箱封号" prop="tran_sealno">
                <el-input v-model="tranForm.tran_sealno" placeholder="请输入集装箱封号" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <!--            <el-col :md="8">
              <el-form-item label="关单号" prop="customs_no">
                <el-input
                  maxlength="20"
                  show-word-limit
                  @input="val => (tranForm.customs_no = helper.keepEngNum(val))"
                  v-model="tranForm.customs_no"
                  placeholder="请输入集装箱号"
                ></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :md="8">
              <el-form-item label="箱型" prop="box_type">
                <el-input :maxlength="8" v-model="tranForm.box_type" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="装箱日期" prop="pack_date">
                <el-date-picker v-model="tranForm.pack_date" value-format="timestamp" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="订舱编号" prop="booking_space">
                <el-input maxlength="20" show-word-limit v-model="tranForm.booking_space" placeholder="请输入订舱编号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="订舱日期" prop="booking_space_date">
                <el-date-picker v-model="tranForm.booking_space_date" placeholder="选择日期" type="date" value-format="timestamp"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="进仓编号" prop="deli_no">
                <el-input maxlength="30" show-word-limit size="small" v-model="tranForm.deli_no" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="进仓日期" prop="deli_date">
                <el-date-picker v-model="tranForm.deli_date" value-format="timestamp" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="提单号/关单号" prop="tran_lading">
                <el-input v-model="tranForm.tran_lading" maxlength="30" placeholder="请输入提单号" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="8">
              <el-form-item label="报关日期" prop="tran_cldate">
                <el-date-picker v-model="tranForm.tran_cldate" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>-->
            <el-col :md="8">
              <el-form-item label="放单日期" prop="tran_bldate">
                <el-date-picker v-model="tranForm.tran_bldate" placeholder="选择日期" type="date"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="发票号备注" prop="tran_no_remark">
                <el-input v-model="tranForm.tran_no_remark" maxlength="50" placeholder="请输入" show-word-limit size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="vd_dash"></div>
          <el-row v-if="!loadingFlag">
            <el-col>
              <el-tabs v-model="activeName2" type="border-card">
                <el-tab-pane label="出运明细" name="first" :key="'first'">
                  <ProdDetail
                    ref="prodDetailRef"
                    :isShow="isShow"
                    :tranForm="tranForm"
                    :companyList="companyList"
                    :newTranProdId="newTranProdId"
                    @changeProdSubtotal="changeProdSubtotal"
                    @newTranProdIdChange="newTranProdIdChange"
                    @sconNoTypeListChange="sconNoTypeListChange"
                    @prodTotalPriceChange="prodTotalPriceChange"
                    @changeListLWH="changeListLWH"
                    @getSconSon="getSconSon"
                    @linkageGrossWeight="linkageGrossWeight"
                    @linkageNWeight="linkageNWeight"
                    @linkageTvol="linkageTvol"
                  >
                  </ProdDetail>
                </el-tab-pane>
                <el-tab-pane label="报关明细" name="second" :key="'second'">
                  <PartDetail
                    :companyList="companyList"
                    :isShow="isShow"
                    ref="partDetailRef"
                    :tranForm="tranForm"
                    @prodReductionKey="prodReductionKey"
                  ></PartDetail>
                </el-tab-pane>
                <el-tab-pane label="商检明细" name="third" :key="'third'">
                  <InspDetail :companyList="companyList" :isShow="isShow" ref="inspDetailRef" :tranForm="tranForm"></InspDetail>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="vd_dash"></div>
          <el-row v-if="!loadingFlag">
            <el-tabs value="tempAddTab" class="vd_eltab vd_buttom" :disabled="true">
              <el-tab-pane label="条款明细" name="tempAddTab">
                <ClauseTranList
                  :isShow="isShow"
                  :tranForm="tranForm"
                  :stffOptionLists="stffOptionList"
                  :cptt_bank_list="cptt_bank_list"
                  @tranClauChange="tranClauChange"
                  @tranClauChange2="tranClauChange2"
                  @tranClauChange3="tranClauChange3"
                  :custList="custList"
                  :cpttList="cpttList"
                  ref="ClauseTranList"
                />
              </el-tab-pane>
            </el-tabs>
          </el-row>
          <div class="vd_dash"></div>
          <el-row v-if="!loadingFlag">
            <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
              <el-tab-pane label="费用明细" name="first" :key="'first'">
                <CostTranList
                  ref="CostTranList"
                  :isShow="isShow"
                  @totalExpenses="totalExpenses"
                  @feesTotalPriceChange="feesTotalPriceChange"
                  :tranForm="tranForm"
                  :sconNoTypeList="sconNoTypeList"
                >
                </CostTranList>
              </el-tab-pane>
              <el-tab-pane label="材料信息" name="second" :key="'second'">
                <div class="vg_mb_8">
                  <el-button type="primary" plain size="small" @click="importModrDialogVisible = true">导入</el-button>
                  <el-button type="danger" plain size="small" @click="delTmTableRow">删除</el-button>
                </div>
                <search-table
                  :is-show="isShow"
                  ref="multiTable"
                  :data="tranForm.tran_mtrb_list"
                  :tableRowKey="'tran_mtrb_id'"
                  :columns="tableProperties"
                  @selection-change="val => (tmSeletion = val)"
                  :needPagination="false"
                  :needSearch="false"
                />
                <el-dialog title="导入材料采购合同" :visible.sync="importModrDialogVisible" width="70%" @opened="getModrDialogData">
                  <div class="vg_mb_8">
                    <el-button type="primary" size="small" @click="modrDialogConfirm">确认选择</el-button>
                    <el-button type="info" size="small" @click="modrDialogDataRefresh">刷新</el-button>
                  </div>
                  <DynamicUTable
                    v-if="importModrDialogVisible"
                    ref="modrDialog"
                    :need-pagination="false"
                    :need-search="true"
                    :table-data="modrDialogTableData"
                    :columns="c_modrDialogTableProperties"
                    v-loading="modrDialogFlag"
                    :totalPage="modrDialogTotalPage"
                    @selection-change="val => (modrDialogSelection = val)"
                    @getTableData="getModrDialogData"
                  />
                </el-dialog>
              </el-tab-pane>
            </el-tabs>
          </el-row>
          <el-dialog title="部件" :visible.sync="dialogVisible" width="70%">
            <ClauseTranImport @childConfirm="childConfirm" @childChanel="childChanel" :sconId="tranForm.scon_id" :suppId="tranForm.supp_id"></ClauseTranImport>
          </el-dialog>
        </el-form>
        <!-- 生成进仓单弹窗 -->
        <el-dialog title="生成进仓单" :visible.sync="deliAddDialog" width="70%">
          <div class="vg_mb_16">
            <el-button size="small" type="primary" plain @click="deliAdd()">生成</el-button>
            <el-button size="small" type="danger" plain @click="deliAddDialog = false">取消</el-button>
          </div>
          <PartDetailDeli :companyList="companyList" :isShow="isShow" ref="partDetailDeliRef" :deliCopyList="deliCopyList"></PartDetailDeli>
        </el-dialog>
        <el-dialog title="生成拖箱单" :visible.sync="tuoDialog" width="70%" @opened="getTuoTableData">
          <div class="vg_mb_16">
            <el-button size="small" type="primary" plain @click="exportTuo()">生成</el-button>
            <el-button size="small" type="danger" plain @click="tuoDialog = false">取消</el-button>
            <el-button type="info" size="small" icon="el-icon-refresh-right" @click="getTuoTableData">刷新</el-button>
          </div>
          <search-table
            ref="tuoDialog"
            :data="tuoTableData"
            :tableRowKey="'tuoDialog'"
            :columns="tuoDialogTableProperties"
            :need-fixed-height="true"
            @selection-change="val => (tuoDialogSelections = val)"
            @getTableData="getTuoTableData"
          />
          <div>总立方数:{{ objectArrayReduce(tuoDialogSelections || [], 'prod_tvol') }}</div>
        </el-dialog>
        <el-dialog title="打印下载相关单据" :visible.sync="printDialogFlag" width="550px">
          <el-form label-width="auto">
            <el-row>
              <el-col :md="24">
                <el-form-item label="报关发票:">
                  <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintPart">打印</el-button>
                  <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportPart">下载PDF </el-button>
                  <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportPartExcel">下载excel </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="报关装箱单:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintBox(1)">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportBox(1)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportBoxExcel(1)">下载excel </el-button>
            </el-form-item>
            <el-form-item label="报关装箱单(含采购合同号):">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintBox(3)">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportBox(3)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportBoxExcel(3)">下载excel </el-button>
            </el-form-item>
            <el-form-item label="报关装箱单(分别打印):">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintBox(2)">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportBox(2)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportBoxExcel(2)">下载excel </el-button>
            </el-form-item>
            <el-form-item label="出口货物委托:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintExitus">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportExitus">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportExitusExcel">下载excel </el-button>
            </el-form-item>
            <el-form-item label="结汇发票:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintRemit">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportRemit">下载PDF</el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportRemitExcel">下载excel </el-button>
            </el-form-item>
            <el-form-item label="结汇装箱单:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintSettSheet(1)">打印 </el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportSettSheet(1)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportSettSheetExcel(1)"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="结汇装箱单(分别打印):">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintSettSheet(2)">打印 </el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportSettSheet(2)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportSettSheetExcel(2)"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="商检发票:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintInvoice">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportInvoice">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportInvoiceExcel">下载excel </el-button>
            </el-form-item>
            <el-form-item label="商检销售合同:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintContract">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportContract">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportContractExcel">下载excel </el-button>
            </el-form-item>
            <el-form-item label="商检装箱单:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintCommodity(1)">打印 </el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportCommodity(1)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportCommodityExcel(1)"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="商检装箱单(分别打印):">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintCommodity(2)">打印 </el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportCommodity(2)">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportCommodityExcel(2)"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="报关明细:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickDeclareAtCustoms">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportDeclareAtCustoms"> 下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportDeclareAtCustomsExcel"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="辅料信息:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickexportTranMtrb">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportexportTranMtrb">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportexportTranMtrbExcel"> 下载excel </el-button>
            </el-form-item>
            <el-form-item label="报关发票HK:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickExportHoins">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportHoinsPdf">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportHoinsExcel">下载excel </el-button>
            </el-form-item>
            <el-form-item label="出口货物报关单:">
              <el-button icon="el-icon-bottom" size="small" type="success" @click="exportDeclarationExcel">下载excel </el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-col>
    </el-row>
    <el-table v-show="false" class="vg_cursor" id="exportTableDataOId" ref="multiTable" :data="exportTableDataO">
      <el-table-column label="客户简称" prop="cust_abbr" />
      <el-table-column label="客户订单号" prop="scon_cust_nos" />
      <el-table-column label="外销订单号" prop="scon_no" />
      <el-table-column label="采购订单号" prop="podr_no" />
      <el-table-column label="客户货号" prop="prod_cust_no" />
      <el-table-column label="中文描述" prop="chin_desc" />
      <el-table-column label="英文描述" prop="engi_desc" />
      <el-table-column label="数量" prop="prod_ship" />
      <el-table-column label="货号日期" prop="arti_date" />
      <el-table-column label="截止日期" prop="clos_date" />
      <el-table-column label="确认人" prop="conf_people" />
      <el-table-column label="港口" prop="cust_dport" />
    </el-table>
    <table v-show="false" id="exportTableDataTId" border="1px" width=" 100%" cellpadding="0" cellspacing="0" class="vd_table printd">
      <tr>
        <th class="vd_th">PO#</th>
        <th class="vd_th">ITEM#</th>
        <th class="vd_th">QTY</th>
        <th class="vd_th">Cartons</th>
        <th class="vd_th">CBM</th>
        <th class="vd_th">Code</th>
        <th class="vd_th">Port of Origin</th>
        <th class="vd_th">ETDeparture Vessel</th>
        <th class="vd_th">ETArrival - Port</th>
        <th class="vd_th">ETA - KONG</th>
        <th class="vd_th">Destination</th>
        <th class="vd_th vd_borrn">Booking ref#</th>
      </tr>
      <tr v-for="(item, index) in exportTableDataT.list" :key="index">
        <!-- <tr> -->
        <td class="vd_td_t">
          {{ item.scon_cust_nos }}
        </td>
        <td class="vd_td_t">
          {{ item.prod_cust_no }}
        </td>
        <td class="vd_td_t">
          {{ item.prod_ship }}
        </td>
        <td class="vd_td_t">
          {{ item.prod_box_num }}
        </td>
        <td class="vd_td_t">
          {{ item.prod_tvol }}
        </td>
        <td class="vd_td_t">
          {{ item.supp_no }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t" v-if="index === 0">
          {{ item.tran_sport }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t" v-if="index === 0">
          {{ item.tran_flight }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t" v-if="index === 0">
          {{ item.cust_dport }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t" v-if="index === 0">
          {{ item.EAT }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t" v-if="index === 0">
          {{ item.cust_dcountry }}
        </td>
        <td :rowspan="exportTableDataT.list.length" class="vd_td_t vd_borrn" v-if="index === 0">
          {{ item.tran_lading }}
        </td>
      </tr>
      <tr>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
      </tr>
      <tr>
        <td class="vd_td_t" colspan="2">Total:</td>
        <td class="vd_td_t">{{ exportTableDataT.total1 }}</td>
        <td class="vd_td_t">{{ exportTableDataT.total2 }}</td>
        <td class="vd_td_t">{{ exportTableDataT.total3 }}</td>
      </tr>
      <tr>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
        <td class="vd_td_t"></td>
      </tr>
      <tr>
        <td class="vd_td_t" colspan="2">Container Size</td>
        <td class="vd_td_t" colspan="2">{{ exportTableDataT.consize }}</td>
      </tr>
      <tr>
        <td class="vd_td_t" colspan="2">Container #</td>
        <td class="vd_td_t" colspan="2">{{ exportTableDataT.contain }}</td>
      </tr>
      <tr>
        <td class="vd_td_t" colspan="2">ETD</td>
        <td class="vd_td_t" colspan="2">{{ exportTableDataT.ETD }}</td>
      </tr>
    </table>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="机构信息" :visible.sync="dialogInstVisible2" width="70%">
      <ImportInst @importInstChange="importInstChange" />
    </el-dialog>
    <el-dialog title="客户订单号" :visible.sync="dialogInstVisible3" width="70%">
      <ImportSconCust ref="ImportSconCust" :tranForm="tranForm" @importSconCustChange="importSconCustChange" />
    </el-dialog>
    <el-dialog title="查看报关汇总明细" :visible.sync="dialogVisibleGroup" width="70%">
      <PartGroup :tranForm="tranForm"></PartGroup>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { get, post, postNoCatch } from '@/api/request';
import { optnAPI } from '@api/modules/optn';
import { tranAPI } from '@/api/modules/tran';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import { deliAPI } from '@/api/modules/deli';
import ImportInst from './Component/ImportInst.vue';
import { BigNumber } from 'bignumber.js';
import { useCpttMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import CostTranList from './Component/CostTranList.vue';
import ClauseTranList from './Component/ClauseTranList.vue';
import ProdDetail from './Component/ProdDetail.vue';
import PartDetail from './Component/PartDetail.vue';
import PartDetailDeli from './Component/PartDetailDeli.vue';
import InspDetail from './Component/InspDetail.vue';
import ClauseTranImport from './Component/ClauseTranImport.vue';
import htmlToExcel from '@/plugins/htmlToExcel';
import { packAPI } from '@api/modules/pack';
import { modrDialogTableProperties, tableProperties, tuoDialogTableProperties } from '@/views/TransportationManagement/TranManage/tran';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { arrayDeRepeat, deleteTableRow, getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import helper from '@assets/js/helper';
import ImportSconCust from './Component/ImportSconCust.vue';
import PartGroup from '@/views/TransportationManagement/TranManage/TabChild/Component/PartGroup.vue';
import { changeStaffForm } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'tranEditMain',
  computed: {
    BigNumber() {
      return BigNumber;
    },
    helper() {
      return helper;
    },
    c_modrDialogTableProperties() {
      this.modrDialogTableProperties.forEach(item => {
        if (item.label === '销售合同号')
          item.options = arrayDeRepeat(getArrayIds(this.tranForm.tran_prod_list, 'scon_no')).map(m => {
            return { value: m, label: m };
          });
      });
      return this.modrDialogTableProperties;
    }
  },
  mixins: [useSuppMixin, useCpttMixin, useStffMixin, useDeptMixin],
  components: {
    DynamicUTable,
    PartGroup,
    ImportSconCust,
    SearchTable,
    editHeader,
    inputUser,
    CostTranList,
    ClauseTranList,
    ProdDetail,
    PartDetail,
    InspDetail,
    ClauseTranImport,
    ImportInst,
    PartDetailDeli
  },
  data() {
    return {
      dialogVisibleGroup: false,
      modrDialogTotalPage: 0,
      modrDialogFlag: false,
      modrDialogTableProperties: modrDialogTableProperties,
      modrDialogSelection: [],
      modrDialogTableData: [],
      importModrDialogVisible: false,
      tmSeletion: [],
      tmTableMap: new Map(),
      tableProperties: tableProperties,
      isShow: true,
      activeName: 'first',
      activeName2: 'first',
      btn: { edit: true },
      stffForm: {},
      deliAddDialog: false, // 进仓单dialog
      tuoDialog: false, // 拖箱单dialog
      multipleSelection: [], // 进仓单弹窗多选数据
      dialogInstVisible2: false,
      dialogInstVisible3: false,
      tranForm: {
        tran_id: null,
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cptt_aid: null, //公司抬头
        tran_stff_id: null, //出运经办人
        scon_nos: null, //外销合同
        scon_cust_nos: null, //客户订单号
        cust_payway: null, //付款方式
        cust_paylaw: null, //价格条款
        tran_ivdate: null, //发票日期
        cust_shipway: null, //运输方式
        cust_shippay: null, //运费支付方式
        tran_ppdate: null, //应收日期
        tran_prtotal: null, //出运金额
        cust_currency: 'USD', //币种
        tran_fetotal: '0.0000', //费用金额
        tran_rmbtotal: '0.0000', //人民币金额
        tran_usdrate: '6', //汇率
        tran_flight: null, //航名航班信息
        tran_sport: null, //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        tran_spdate: null, //装运日期
        tran_dpdate: null, //离港日
        tran_eta: null, //到港日
        tran_cldate: null, //报关日期
        inst_id: null, //货代简称
        inst_abbr: null, //货代简称
        tran_lading: null, //提单号
        tran_bldate: null, //放单日期
        tran_containo: null, //集装箱号
        tran_sealno: null, //集装箱封号
        inst_bid: null, //通知人id
        inform_name: null, //通知人名称
        cnee_name: null, //收货联系人
        // cnee_phone: null, //收货联系电话
        cnee_site: null, //收货人地址
        inform_site: null, //通知人地址
        clau_content: null, //送货资料
        clau_bcontent: null, //船公司联系
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null, //银行信息
        cust_baddr: null, //客户地址
        inst_cid: null, //送货仓库id
        inst_cabbr: null, //送货仓库简称
        inst_ccontact: null, //送货联系人
        inst_ccphone: null, //送货联系人电话
        inst_caddr: null, //送货地址
        cust_shipmark: null, //唛头
        booking_space: null,
        tran_fees_list: [],
        tran_part_list: [],
        tran_prod_list: [],
        tran_insp_lsit: [],
        scon_id: null, //销售合同号
        tran_no: ''
        // tran_no1: '', //发票号前10位,不可变
        // tran_no2: '', //发票号前4位
        // tran_no3: '' //发票号后1位
      },
      feesKey: 0,
      dialogVisible: false,
      feesListCopy: [],
      partListCopy: [],
      prodListCopy: [],
      inspListCopy: [],
      stffOptionList: [],
      custCurrencyList: [],
      custPaywayList: [],
      custPaylawList: [],
      custShipwayList: [],
      custShippayList: [],
      TranSportList: [],
      portOfShipmentList: [
        { label: 'SHANGHAI', value: 'SHANGHAI' },
        { label: 'NINGBO', value: 'NINGBO' },
        { label: 'QINGDAO', value: 'QINGDAO' },
        { label: 'SHENZHEN', value: 'SHENZHEN' }
      ],
      custDportList: [],
      custDcountryList: [],
      cpttList: [],
      custList: [],
      cptt_bank_list: [], //银行
      tranFormRules: {
        cptt_aid: [{ required: true, message: ' ', trigger: 'change' }],
        tran_stff_id: [{ required: true, message: ' ', trigger: 'change' }],
        cust_payway: [{ required: true, message: ' ', trigger: 'change' }],
        cust_paylaw: [{ required: true, message: ' ', trigger: 'change' }],
        tran_ivdate: [{ required: true, message: ' ', trigger: 'change,blur' }],
        cust_shipway: [{ required: true, message: ' ', trigger: 'change' }],
        cust_shippay: [{ required: true, message: ' ', trigger: 'change' }],
        tran_ppdate: [{ required: true, message: ' ', trigger: 'change,blur' }],
        cust_currency: [{ required: true, message: ' ', trigger: 'change,blur' }],
        tran_sport: [{ required: true, message: ' ', trigger: 'change' }]
      },
      newTranProdId: -1,
      sconNoTypeList: [],
      companyList: [],
      deliCopyList: [], // 查询到的未生成进仓单的报关明细
      loadingFlag: true,
      isLoading: false,
      printDialogFlag: false,
      printDialogFlagFor34: false,
      exportTableDataO: [],
      exportTableDataT: {
        list: [],
        total1: 0,
        total2: 0,
        total3: 0,
        consize: '',
        contain: '',
        ETD: ''
      },
      tuoTableData: [],
      tuoDialogSelections: [],
      tuoDialogTableProperties: tuoDialogTableProperties,
      yongYouStatus: '未抓取'
    };
  },
  created() {
    this.initData();
  },
  methods: {
    objectArrayReduce,
    initData() {
      this.sconNoTypeList = [];
      this.loadingFlag = true;
      this.getTranInfo();
      this.getCompany();
      this.getCptt();
      this.getCust();
      this.getCustCurrency();
      this.getCustPayway();
      this.getCustPaylaw();
      this.getCustShipway();
      this.getCustShippay();
      this.getTranSport();
      this.getCustDcountry();
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 导出VENDOR
    exportTableT() {
      let dt = new Date();
      let m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'];
      let mn = dt.getMonth();
      let dn = dt.getDate();
      let ETD = new Date(this.tranForm.tran_dpdate);
      let timeA = m[ETD.getMonth()] + '.' + ETD.getDate();
      let num = dn;
      if (this.tranForm.cust_dport === 'SOUTHAMPTON') {
        num += 30;
      } else if (this.tranForm.cust_dport === 'LB') {
        num += 20;
      } else if (this.tranForm.cust_dport === 'OHIO') {
        num += 25;
      } else if (this.tranForm.cust_dport === 'HAMBURG') {
        num += 30;
      } else if (this.tranForm.cust_dport === 'YOKKAICHI') {
        num += 4;
      } else if (this.tranForm.cust_dport === 'MELBOURNE') {
        num += 14;
      }
      let timeB = m[mn] + '.' + num;
      let temp = [];
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(0);
      let totalC = new BigNumber(0);
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        let item = {};
        item.scon_cust_nos = this.tranForm.tran_prod_list[i].scon_cust_no;
        item.prod_cust_no = this.tranForm.tran_prod_list[i].prod_cust_no;
        item.prod_ship = this.tranForm.tran_prod_list[i].prod_ship;
        item.prod_box_num = this.tranForm.tran_prod_list[i].prod_box_num;
        item.prod_tvol = this.tranForm.tran_prod_list[i].prod_tvol;
        item.tran_sport = this.tranForm.tran_sport;
        item.tran_flight = this.tranForm.tran_shipName_voyage;
        item.cust_dport = this.tranForm.cust_dport;
        let ETA = new Date(this.tranForm.tran_eta);
        item.EAT = m[ETA.getMonth()] + '.' + ETA.getDate();
        item.cust_dcountry = this.tranForm.cust_dcountry;
        item.tran_lading = this.tranForm.tran_lading;
        item.supp_no = this.tranForm.tran_prod_list[i].supp_no;
        totalA = totalA.plus(this.tranForm.tran_prod_list[i].prod_ship ? this.tranForm.tran_prod_list[i].prod_ship : 0);
        totalB = totalB.plus(this.tranForm.tran_prod_list[i].prod_box_num ? this.tranForm.tran_prod_list[i].prod_box_num : 0);
        totalC = totalC.plus(this.tranForm.tran_prod_list[i].prod_tvol ? this.tranForm.tran_prod_list[i].prod_tvol : 0);
        temp.push(item);
      }
      totalA = totalA.toNumber();
      totalB = totalB.toNumber();
      totalC = totalC.toNumber();
      this.exportTableDataT.contain = this.tranForm.tran_sealno;
      this.exportTableDataT.ETD = timeA;
      this.exportTableDataT.total1 = totalA;
      this.exportTableDataT.total2 = totalB;
      this.exportTableDataT.total3 = totalC;
      if (totalC >= 25 && totalC < 30) {
        this.exportTableDataT.consize = '1x20’GP';
      } else if (totalC >= 55 && totalC <= 58) {
        this.exportTableDataT.consize = '1x40’GP';
      } else if (totalC >= 65 && totalC <= 68) {
        this.exportTableDataT.consize = '1x40’HQ';
      } else if (totalC > 68) {
        this.exportTableDataT.consize = '1x45’HQ';
      }
      this.exportTableDataT.list = temp;
      setTimeout(() => {
        htmlToExcel.getExcel(`#exportTableDataTId`, this.tranForm.tran_no + 'VENDOR');
      }, 200);
    },
    // 导出第三方
    exportTableO() {
      let temp = [];
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        let item = {};
        item.cust_abbr = this.tranForm.cust_abbr;
        item.scon_cust_nos = this.tranForm.tran_prod_list[i].scon_cust_no;
        item.scon_no = this.tranForm.tran_prod_list[i].scon_no;
        item.podr_no = this.tranForm.tran_prod_list[i].podr_no;
        item.prod_cust_no = this.tranForm.tran_prod_list[i].prod_cust_no;
        item.chin_desc = this.tranForm.tran_prod_list[i].prod_name;
        item.engi_desc = this.tranForm.tran_prod_list[i].prod_ename;
        item.prod_ship = this.tranForm.tran_prod_list[i].prod_ship;
        item.arti_date = '';
        item.clos_date = '';
        item.conf_people = '';
        item.cust_dport = this.tranForm.cust_dport;
        temp.push(item);
      }
      this.exportTableDataO = temp;
      setTimeout(() => {
        htmlToExcel.getExcel(`#exportTableDataOId`, this.tranForm.tran_no + '验货表');
      }, 200);
    },
    //导出excel
    exportTuo() {
      packAPI.addPack(this.tuoDialogSelections).then(async ({ data }) => {
        await (this.tuoDialog = false);
        await this.jump('/pack_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 169,
              form_id: data.form_id
            })
          )
        });
      });
    },
    getTuoTableData() {
      tranAPI.getTranPartByPack({ tran_id: this.tranForm.tran_id }).then(({ data }) => {
        this.tuoTableData = data;
      });
    },
    // 生成进仓单dialog
    deliDialog() {
      // if (this.tranForm.status !== 2) {
      //   return this.$message.warning('该单据未生效');
      // }
      // const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      // // 查询未生成进仓单的报关明细
      // get(deliAPI.checkCreatDeli, {
      //   tran_id: props.form_id
      // }).then(res => {
      //   this.deliCopyList = JSON.parse(JSON.stringify(res.data.data.list));
      // });
      // this.deliAddDialog = true;
      this.$confirm('确认生成进仓单?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.deliAdd();
        })
        .catch(err => console.error(err));
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 计算到港日
    custDportChange() {
      this.TranSportList.forEach(item => {
        if (item.param1 === this.tranForm.cust_dport) {
          if (this.tranForm.tran_dpdate) {
            this.tranForm.tran_eta = new Date(this.tranForm.tran_dpdate).getTime() + Number(item.param2) * 60 * 60 * 24 * 1000;
          }
        }
      });
    },
    // 刷新按钮
    buttonRefresh() {
      this.sconNoTypeList = [];
      this.initData();
    },
    // 计算应收日期
    tranSpdateChange() {
      let value = new Date(this.tranForm.tran_spdate).getTime();
      value = value + 24 * 60 * 60 * 60 * 1000;
      this.tranForm.tran_ppdate = value;
      this.custDportChange();
    },
    // 生成进仓单
    deliAdd() {
      // let tran_part_ids = [];
      // for (let i = 0; i < this.$refs.partDetailDeliRef.selectionsList.length; i++) {
      //   tran_part_ids[i] = this.$refs.partDetailDeliRef.selectionsList[i].tran_part_id;
      // }
      post(deliAPI.addDeli, {
        tran_id: this.tranForm.tran_id
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '生成进仓单成功'
          });
          this.jump('/deli_list', { perm_id: 131, tran_no: this.tranForm.tran_no });
        } else if (res.data.code === 12) {
          this.$message({
            type: 'error',
            message: '请勿选择不同供应商的报关明细'
          });
        } else if (res.data.code === 2) {
          this.$message({
            type: 'error',
            message: '暂无数据'
          });
        } else {
          this.$message.error(res.data.msg);
        }

        this.deliAddDialog = false;
      });
    },

    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.sconNoTypeList = [];
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let prodDetail = await this.$refs.prodDetailRef.$refs.ProdDetail.fullValidateMsg();
          let partDetail = await this.$refs.partDetailRef.$refs.PartDetail.fullValidateMsg();
          let inspDetail = await this.$refs.inspDetailRef.$refs.InspDetail.fullValidateMsg();
          this.$refs[formName].validate((valid, object) => {
            if (valid && !prodDetail && !partDetail && !inspDetail) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (prodDetail) tempStr += `出运明细子表-${prodDetail}`;
              if (partDetail) tempStr += `报关明细子表-${partDetail}`;
              if (inspDetail) tempStr += `商检明细子表-${inspDetail}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    //同步出运报关商检
    getSconSon() {
      this.$confirm('确认同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        postNoCatch(tranAPI.getTranSonTable, {
          tran_prod_list: this.tranForm.tran_prod_list,
          tran_part_list: this.tranForm.tran_part_list,
          tran_insp_list: this.tranForm.tran_insp_list
        }).then(res => {
          this.$set(this.tranForm, 'tran_prod_list', []);
          for (let i = 0; i < res.data.tran_prod_list.length; i++) {
            this.tranForm.tran_prod_list.push(res.data.tran_prod_list[i]);
            this.$refs.prodDetailRef.prodBoxNum(this.tranForm.tran_prod_list.length - 1); // 计算箱数 体积 总体积 总毛净重
            this.$refs.prodDetailRef.prodSubtotal(this.tranForm.tran_prod_list.length - 1); // 计算金额
          }
          this.$set(this.tranForm, 'tran_part_list', []);
          for (let i = 0; i < res.data.tran_part_list.length; i++) {
            let val = cloneDeep(res.data.tran_part_list[i]);
            //以下字段取值对应成品
            let filter = this.tranForm.tran_prod_list.filter(item => item.scon_prod_id === val.scon_prod_id);
            if (filter.length > 0) {
              val.prod_boxl = filter[0].prod_boxl; //长
              val.prod_boxw = filter[0].prod_boxw; //宽
              val.prod_boxh = filter[0].prod_boxh; //高
              val.prod_qpc = filter[0].prod_qpc; //装箱数
              val.prod_box_num = filter[0].prod_box_num; //箱数
              val.prod_gweight = filter[0].prod_gweight; //每箱毛重
              val.prod_nweight = filter[0].prod_nweight; //每箱净重
              val.prod_vol = filter[0].prod_vol; //每箱体积
              val.prod_tgweight = filter[0].prod_tgweight; //总毛重
              val.prod_tnweight = filter[0].prod_tnweight; //总净重
              val.prod_tvol = filter[0].prod_tvol; //总体积
            }
            this.tranForm.tran_part_list.push(val);
            // this.$refs.partDetailRef.prodBoxNum(this.tranForm.tran_part_list.length - 1); // 计算箱数 体积 总体积 总毛净重
            this.$refs.partDetailRef.prodSubtotal(this.tranForm.tran_part_list.length - 1); // 计算金额
          }
          this.$set(this.tranForm, 'tran_insp_list', []);
          for (let i = 0; i < res.data.tran_insp_list.length; i++) {
            this.tranForm.tran_insp_list.push(res.data.tran_insp_list[i]);
            this.$refs.inspDetailRef.prodBoxNum(this.tranForm.tran_insp_list.length - 1); // 计算箱数 体积 总体积 总毛净重
            this.$refs.inspDetailRef.prodSubtotal(this.tranForm.tran_insp_list.length - 1); // 计算金额
          }
          this.$message.success('同步成功！');
        });
      });
    },
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let tranForm = JSON.parse(JSON.stringify(this.tranForm));
      tranForm.tran_ivdate = this.helper.getNewDate(tranForm.tran_ivdate); //发票日期
      tranForm.tran_ppdate = this.helper.getNewDate(tranForm.tran_ppdate); //报关日期
      tranForm.tran_cldate = this.helper.getNewDate(tranForm.tran_cldate); //应收日期
      tranForm.tran_bldate = this.helper.getNewDate(tranForm.tran_bldate); //放单日期
      tranForm.tran_spdate = this.helper.getNewDate(tranForm.tran_spdate); //装运日期
      tranForm.tran_dpdate = this.helper.getNewDate(tranForm.tran_dpdate); //离港日期
      tranForm.tran_eta = this.helper.getNewDate(tranForm.tran_eta); //到港日期
      tranForm.tran_id = props.form_id;
      //报关明细
      this.partListCopy = this.partListCopy.filter(item => tranForm.tran_part_list.every(item1 => item.tran_part_id !== item1.tran_part_id));
      for (let i in this.partListCopy) {
        this.partListCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < tranForm.tran_part_list.length; i++) {
        tranForm.tran_part_list[i].index = Number(i);
        tranForm.tran_part_list[i].prod_tgweight += '';
      }
      tranForm.tran_part_list = tranForm.tran_part_list.concat(this.partListCopy);
      //费用明细
      this.feesListCopy = this.feesListCopy.filter(item => tranForm.tran_fees_list.every(item1 => item.tran_fees_id !== item1.tran_fees_id));
      for (let i in this.feesListCopy) {
        this.feesListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_fees_list = tranForm.tran_fees_list.concat(this.feesListCopy);
      //出运明细
      this.prodListCopy = this.prodListCopy.filter(item => tranForm.tran_prod_list.every(item1 => item.tran_prod_id !== item1.tran_prod_id));
      for (let i in this.prodListCopy) {
        this.prodListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_prod_list = tranForm.tran_prod_list.concat(this.prodListCopy);
      //商检明细
      this.inspListCopy = this.inspListCopy.filter(item => tranForm.tran_insp_list.every(item1 => item.tran_insp_id !== item1.tran_insp_id));
      for (let i in this.inspListCopy) {
        this.inspListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_insp_list = tranForm.tran_insp_list.concat(this.inspListCopy);
      tranForm.tran_mtrb_list = tranForm.tran_mtrb_list?.concat(Array.from(this.tmTableMap.values()));
      post(tranAPI.editTran, tranForm)
        .then(res => {
          if (res.data.code === 0) {
            this.sconNoTypeList = [];
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 999) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },

    // 获取byid总信息
    async getTranInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.tranForm.tran_id = props.form_id;
      await get(tranAPI.getTranById, { tran_id: this.tranForm.tran_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tranForm = res.data.data.form;
            this.btn = res.data.data.btn;
            if (this.tranForm.autoInc) this.btn.edit = false;
            this.getCompany();
            this.feesListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_fees_list));
            this.partListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_part_list));
            this.prodListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_prod_list));
            this.inspListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_insp_list));
            //如果出运发票号无杠则加杠
            if (this.tranForm.tran_no.length === 10) {
              this.tranForm.tran_no = this.tranForm.tran_no + '-';
            }
            if (this.tranForm.create_time === this.tranForm.update_time) this.tranForm.cust_dcountry = 'USA';
            this.tranForm.tran_ivdate = this.tranForm.tran_ivdate === 0 ? new Date().getTime() : Number(this.tranForm.tran_ivdate) * 1000;
            this.tranForm.tran_ppdate = this.tranForm.tran_ppdate ? Number(this.tranForm.tran_ppdate) * 1000 : null;
            this.tranForm.tran_cldate = this.tranForm.tran_cldate ? Number(this.tranForm.tran_cldate) * 1000 : null;
            this.tranForm.tran_bldate = this.tranForm.tran_bldate ? Number(this.tranForm.tran_bldate) * 1000 : null;
            if (this.tranForm.tran_spdate) {
              this.tranForm.tran_spdate = Number(this.tranForm.tran_spdate) * 1000;
              this.tranForm.tran_ppdate = this.tranForm.tran_spdate + 24 * 60 * 60 * 60 * 1000;
            } else {
              this.tranForm.tran_spdate = null;
            }
            this.tranForm.tran_dpdate = this.tranForm.tran_dpdate ? Number(this.tranForm.tran_dpdate) * 1000 : null;
            this.tranForm.tran_eta = this.tranForm.tran_eta ? Number(this.tranForm.tran_eta) * 1000 : null;

            //材料子表change
            this.tableProperties.find(x => x.prop === 'inv_num').subItem.change = (val, row, options) => {
              this.$set(row, 'inv_money', parseFloat(new BigNumber(Number(row.inv_num)).times(Number(row.prod_mtrb_price)).toFixed(12)).toString());
            };
            this.tableProperties.find(x => x.prop === 'prod_mtrb_price').subItem.change = (val, row, options) => {
              this.$set(row, 'inv_money', parseFloat(new BigNumber(Number(row.inv_num)).times(Number(row.prod_mtrb_price)).toFixed(12)).toString());
            };

            this.getCpttBank(0);
            changeStaffForm(this.stffForm, this.tranForm);
            //用友抓取后不可反生效不可编辑
            this.$emit('isYongYou', false);
            this.yongYouStatus = '未抓取';
            this.tranForm.tran_part_list.forEach(item => {
              if (item.nsaleBillVouch_Status === 1) {
                this.btn.edit = false;
                this.$emit('isYongYou', true);
                this.yongYouStatus = '已抓取';
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
      await (this.loadingFlag = false);
      //计算费用key值
      await this.$refs.CostTranList.reductionKey();
      //计算出运报关商检key值
      await this.$refs.prodDetailRef.reductionKey();
      //计算报关key值
      await this.$refs.partDetailRef.reductionKey();
    },
    // 更换公司抬头
    cpttChange() {
      for (let i = 0; i < this.cpttList.length; i++) {
        if (this.tranForm.cptt_aid === this.cpttList[i].cptt_id) {
          this.tranForm.cptt_aname = this.cpttList[i].cptt_name;
          this.tranForm.cptt_aname_en = this.cpttList[i].cptt_name_en;
          this.tranForm.cptt_phone = this.cpttList[i].cptt_phone;
          this.tranForm.cptt_address_en = this.cpttList[i].cptt_address_en;
          // let str = this.tranForm.tran_no1.substring(2,this.tranForm.tran_no1.length)
          this.tranForm.tran_no1 = this.cpttList[i].cptt_no + this.tranForm.tran_prod_list[0].scon_no;
          this.getCpttBank(1);
        }
      }
    },
    // 计算内容
    calcForm() {
      let sconNoList = [];
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        this.tranForm.tran_prod_list[i].key = Number(i);
        this.tranForm.tran_prod_list[i].prod_subtotal = this.helper.haveFour(
          (Number(this.tranForm.tran_prod_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_box_num = Math.ceil(
          Number(this.tranForm.tran_prod_list[i].prod_ship) / Number(this.tranForm.tran_prod_list[i].prod_qpc)
        );
        this.tranForm.tran_prod_list[i].prod_usd = this.helper.haveFour(this.tranForm.tran_prod_list[i].prod_usd);
        if (!this.tranForm.tran_prod_list[i].prod_box_unit) {
          this.tranForm.tran_prod_list[i].prod_box_unit = 'CTNS';
        }
        if (sconNoList.indexOf(this.tranForm.tran_prod_list[i].scon_no) === -1) {
          sconNoList.push(this.tranForm.tran_prod_list[i].scon_no);
        }
        this.tranForm.tran_prod_list[i].prod_subtotal = this.helper.haveFour(
          (Number(this.tranForm.tran_prod_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_box_num = Math.ceil(
          Number(this.tranForm.tran_prod_list[i].prod_ship) /
            (Number(this.tranForm.tran_prod_list[i].prod_qpc) ? Number(this.tranForm.tran_prod_list[i].prod_qpc) : 1)
        );
        this.tranForm.tran_prod_list[i].prod_tgweight = this.helper.calcNum3(
          (Number(this.helper.calcNum3(this.tranForm.tran_prod_list[i].prod_gweight)) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_tnweight = this.helper.calcNum3(
          (Number(this.tranForm.tran_prod_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
        let numVol = new BigNumber(1);
        numVol = numVol
          .times(this.tranForm.tran_prod_list[i].prod_boxl ? this.tranForm.tran_prod_list[i].prod_boxl : 0)
          .times(this.tranForm.tran_prod_list[i].prod_boxw ? this.tranForm.tran_prod_list[i].prod_boxw : 0)
          .times(this.tranForm.tran_prod_list[i].prod_boxh ? this.tranForm.tran_prod_list[i].prod_boxh : 0)
          .div(1000000)
          .toNumber();
        this.tranForm.tran_prod_list[i].prod_vol = this.helper.calcNum3(numVol);
        this.tranForm.tran_prod_list[i].prod_tvol = this.helper.calcNum3(
          (Number(this.tranForm.tran_prod_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
      }
      for (let i = 0; i < sconNoList.length; i++) {
        let item = {};
        item.id = i;
        item.label = sconNoList[i];
        this.sconNoTypeList.push(item);
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        if (this.tranForm.tran_part_list[i].tran_part_main === 0) {
          this.tranForm.tran_part_list[i].tran_part_ctne = '0';
          this.tranForm.tran_part_list[i].tran_part_ctns = '0';
          this.tranForm.tran_part_list[i].prod_box_num = '0';
          this.tranForm.tran_part_list[i].prod_boxl = '0.00';
          this.tranForm.tran_part_list[i].prod_boxw = '0.00';
          this.tranForm.tran_part_list[i].prod_boxh = '0.00';
          this.tranForm.tran_part_list[i].prod_vol = '0.000';
          this.tranForm.tran_part_list[i].prod_tvol = '0.000';
          if (this.tranForm.tran_part_list[i].tran_part_claim === '0') {
            this.tranForm.tran_part_list[i].prod_qpc = '0';
            this.tranForm.tran_part_list[i].prod_dusd = '0.0000';
            this.tranForm.tran_part_list[i].prod_subtotal = '0.00';
            this.tranForm.tran_part_list[i].prod_gweight = '0.000';
            this.tranForm.tran_part_list[i].prod_tgweight = '0.000';
            this.tranForm.tran_part_list[i].prod_nweight = '0.00';
            this.tranForm.tran_part_list[i].prod_tnweight = '0.000';
          }
        } else {
          if (this.tranForm.tran_part_list[i].prod_qpc) {
            this.tranForm.tran_part_list[i].prod_box_num = Math.ceil(
              Number(this.tranForm.tran_part_list[i].prod_ship) / Number(this.tranForm.tran_part_list[i].prod_qpc)
            );
          } else {
            this.tranForm.tran_part_list[i].prod_box_num = '0';
          }
        }
        this.tranForm.tran_part_list[i].key = Number(i);
        if (!this.tranForm.tran_part_list[i].prod_box_unit) {
          this.tranForm.tran_part_list[i].prod_box_unit = 'CTNS';
        }
        // this.tranForm.tran_part_list[i].prod_dusd = this.helper.haveFour(this.tranForm.tran_part_list[i].prod_dusd);
        // this.tranForm.tran_part_list[i].prod_subtotal = this.helper.reservedDigits(
        //   (Number(this.tranForm.tran_part_list[i].prod_dusd) * 100000 * Number(this.tranForm.tran_part_list[i].prod_ship)) / 100000
        // );

        let bigNum = new BigNumber(this.tranForm.tran_part_list[i].prod_dusd)
          .times(this.tranForm.tran_part_list[i].prod_ship)
          .minus(this.tranForm.tran_part_list[i].price_gap);
        this.tranForm.tran_part_list[i].prod_subtotal = bigNum.isFinite() ? bigNum.toFixed(2) : '';

        this.tranForm.tran_part_list[i].prod_tgweight = this.helper.calcNum3(
          (Number(this.helper.calcNum3(this.tranForm.tran_part_list[i].prod_gweight)) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_part_list[i].prod_tnweight = this.helper.calcNum3(
          (Number(this.tranForm.tran_part_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
        );
        if (this.tranForm.tran_part_list[i].tran_part_claim === '1') {
          let num = new BigNumber(1);
          num = num
            .times(this.tranForm.tran_part_list[i].prod_boxl ? this.tranForm.tran_part_list[i].prod_boxl : 0)
            .times(this.tranForm.tran_part_list[i].prod_boxw ? this.tranForm.tran_part_list[i].prod_boxw : 0)
            .times(this.tranForm.tran_part_list[i].prod_boxh ? this.tranForm.tran_part_list[i].prod_boxh : 0)
            .div(1000000)
            .toNumber();
          this.tranForm.tran_part_list[i].prod_vol = this.helper.calcNum3(num);
          this.tranForm.tran_part_list[i].prod_tvol = this.helper.calcNum3(
            (Number(this.tranForm.tran_part_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
          );
        }
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
        this.tranForm.tran_insp_list[i].prod_subtotal = this.helper.calcNum2(
          (Number(this.tranForm.tran_insp_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_ship)) / 100000
        );
        if (!this.tranForm.tran_insp_list[i].prod_box_unit) {
          this.tranForm.tran_insp_list[i].prod_box_unit = 'CTNS';
        }
        this.tranForm.tran_insp_list[i].prod_box_num = parseInt(
          (Number(this.tranForm.tran_insp_list[i].prod_ship) * 100000) / Number(this.tranForm.tran_insp_list[i].prod_qpc) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_usd = this.helper.haveFour(this.tranForm.tran_insp_list[i].prod_usd);
        this.tranForm.tran_insp_list[i].prod_subtotal = this.helper.calcNum2(
          (Number(this.tranForm.tran_insp_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_box_num = Math.ceil(
          Number(this.tranForm.tran_insp_list[i].prod_ship) /
            (Number(this.tranForm.tran_insp_list[i].prod_qpc) ? Number(this.tranForm.tran_insp_list[i].prod_qpc) : 1)
        );
        this.tranForm.tran_insp_list[i].prod_tgweight = this.helper.calcNum3(
          (Number(this.helper.calcNum3(this.tranForm.tran_insp_list[i].prod_gweight)) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_tnweight = this.helper.calcNum3(
          (Number(this.tranForm.tran_insp_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
        let numVol = new BigNumber(1);
        numVol = numVol
          .times(this.tranForm.tran_insp_list[i].prod_boxl ? this.tranForm.tran_insp_list[i].prod_boxl : 0)
          .times(this.tranForm.tran_insp_list[i].prod_boxw ? this.tranForm.tran_insp_list[i].prod_boxw : 0)
          .times(this.tranForm.tran_insp_list[i].prod_boxh ? this.tranForm.tran_insp_list[i].prod_boxh : 0)
          .div(1000000)
          .toNumber();
        this.tranForm.tran_insp_list[i].prod_vol = this.helper.calcNum3(numVol);
        this.tranForm.tran_insp_list[i].prod_tvol = this.helper.calcNum3(
          (Number(this.tranForm.tran_insp_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
      }
      for (let i = 0; i < this.tranForm.tran_fees_list.length; i++) {
        this.tranForm.tran_fees_list[i].key = Number(i);
      }
    },
    // 打印下载位置
    // 保管发票
    clickPrintPart() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranPart, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickExportPart() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranPart, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出excel
    clickExportPartExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranPart, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    // 报关装箱单
    clickPrintBox(merge = 1) {
      console.log(merge);
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  res.data.data.forEach(item => window.open('/erpfile' + item.pdf_url + '.pdf'));
                } else {
                  res.data.data.forEach(item => window.open('/file' + item.pdf_url + '.pdf'));
                }
                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickExportBox(merge = 1) {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItem(item.pdf_url, `${item.fileName}.pdf`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出 excel·
    clickExportBoxExcel(merge = 1) {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartPack, { tran_id: str, type: 1, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItemExcel(item.pdf_url, `${item.fileName}.xls`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    // 出口货物委托
    clickPrintExitus() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportGoods, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickExportExitus() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportGoods, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出 excel
    clickExportExitusExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportGoods, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    // 结汇发票
    clickPrintRemit() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSett, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }
                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportRemit() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSett, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportRemitExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSett, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 结汇发票单
    clickPrintSettSheet(merge) {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSettPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  res.data.data.forEach(item => window.open('/erpfile' + item.pdf_url + '.pdf'));
                } else {
                  res.data.data.forEach(item => window.open('/file' + item.pdf_url + '.pdf'));
                }
                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportSettSheet(merge) {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSettPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItem(item.pdf_url, `${item.fileName}.pdf`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportSettSheetExcel(merge) {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportSettPack, { tran_id: str, type: 1, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItemExcel(item.pdf_url, `${item.fileName}.xls`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 商检发票
    clickPrintInvoice() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspInvo, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportInvoice() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspInvo, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportInvoiceExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspInvo, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //  商检销售合同
    clickPrintContract() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspScon, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportContract() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspScon, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportContractExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspScon, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //  商检装箱单
    clickPrintCommodity(merge = 1) {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  res.data.data.forEach(item => window.open('/erpfile' + item.pdf_url + '.pdf'));
                } else {
                  res.data.data.forEach(item => window.open('/file' + item.pdf_url + '.pdf'));
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportCommodity(merge = 1) {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspPack, { tran_id: str, type: 0, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItem(item.pdf_url, `${item.fileName}.pdf`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportCommodityExcel(merge = 1) {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportInspPack, { tran_id: str, type: 1, merge: merge })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItemExcel(item.pdf_url, `${item.fileName}.xls`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //  报关明细
    clickDeclareAtCustoms() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartDeta, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportDeclareAtCustoms() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartDeta, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportDeclareAtCustomsExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportPartDeta, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //  辅料信息
    clickexportTranMtrb() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranMtrb, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportexportTranMtrb() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranMtrb, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 Excel
    clickExportexportTranMtrbExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportTranMtrb, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },

    //HK收款单信息打印
    clickExportHoins() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.exportHoins, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //HK收款单导出PDF·
    clickExportHoinsPdf() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportHoins, { tran_id: str, type: 0 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //HK收款单导出Excel
    clickExportHoinsExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportHoins, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },

    // 打开下载打印弹框
    PrintTotal() {
      this.printDialogFlag = true;
    },
    exportDeclarationExcel() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          get(tranAPI.exportGoodsCustom, { tran_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItemExcel(item.url, `${item.fileName}.xls`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 出运数量更改
    changeProdSubtotal(val, val1) {
      //为1是代表有复制的数据则不联动，直接跳出
      if (this.tranForm.is_linkage === 1) {
        return;
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        if (this.tranForm.tran_part_list[i].scon_prod_id === val) {
          //出运数量根据用量做计算
          this.tranForm.tran_part_list[i].prod_ship = Math.ceil(Number(val1) * Number(this.tranForm.tran_part_list[i].prod_use));
          //计算金额
          this.tranForm.tran_part_list[i].prod_subtotal = this.helper.reservedDigits(
            (Number(this.tranForm.tran_part_list[i].prod_dusd) * 100000 * Number(this.tranForm.tran_part_list[i].prod_ship)) / 100000
          );
          // }
          //主商品时联动：出运数量 计算：箱数 总毛净重
          if (Number(this.tranForm.tran_part_list[i].tran_part_main) === 1) {
            //计算箱数总毛净重
            if (this.tranForm.tran_part_list[i].prod_qpc === '' || Number(this.tranForm.tran_part_list[i].prod_qpc) === 0) {
              this.tranForm.tran_part_list[i].prod_qpc = 0;
              this.tranForm.tran_part_list[i].prod_box_num = 0;
            } else {
              this.tranForm.tran_part_list[i].prod_box_num = Math.ceil(
                Number(this.tranForm.tran_part_list[i].prod_ship) / Number(this.tranForm.tran_part_list[i].prod_qpc)
              );
            }
            //计算总毛重
            this.tranForm.tran_part_list[i].prod_tgweight = this.helper.calcNum3(
              (Number(this.helper.calcNum3(this.tranForm.tran_part_list[i].prod_gweight)) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) /
                100000
            );
            //计算总净重
            this.tranForm.tran_part_list[i].prod_tnweight = this.helper.calcNum3(
              (Number(this.tranForm.tran_part_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
            );
            //主商品又报关时计算每箱体积 总体积
            if (Number(this.tranForm.tran_part_list[i].tran_part_claim) === 1) {
              let num = new BigNumber(1);
              num = num
                .times(this.tranForm.tran_part_list[i].prod_boxl ? this.tranForm.tran_part_list[i].prod_boxl : 0)
                .times(this.tranForm.tran_part_list[i].prod_boxw ? this.tranForm.tran_part_list[i].prod_boxw : 0)
                .times(this.tranForm.tran_part_list[i].prod_boxh ? this.tranForm.tran_part_list[i].prod_boxh : 0)
                .div(1000000)
                .toNumber();
              this.tranForm.tran_part_list[i].prod_vol = this.helper.calcNum3(num);
              this.tranForm.tran_part_list[i].prod_tvol = this.helper.calcNum3(
                (Number(this.tranForm.tran_part_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
              );
            }
          }
        }
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        if (this.tranForm.tran_insp_list[i].scon_prod_id === val) {
          this.tranForm.tran_insp_list[i].prod_ship = val1;
          this.tranForm.tran_insp_list[i].prod_subtotal = this.helper.reservedDigits(
            (Number(this.tranForm.tran_insp_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_ship)) / 100000
          );
          if (this.tranForm.tran_insp_list[i].prod_qpc === '' || this.tranForm.tran_insp_list[i].prod_qpc === '0') {
            this.tranForm.tran_insp_list[i].prod_qpc = 0;
            this.tranForm.tran_insp_list[i].prod_box_num = 0;
          } else {
            this.tranForm.tran_insp_list[i].prod_box_num = Math.ceil(
              Number(this.tranForm.tran_insp_list[i].prod_ship) / Number(this.tranForm.tran_insp_list[i].prod_qpc)
            );
          }
          this.tranForm.tran_insp_list[i].prod_tgweight = this.helper.calcNum3(
            (Number(this.tranForm.tran_insp_list[i].prod_gweight) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
          );
          this.tranForm.tran_insp_list[i].prod_tnweight = this.helper.calcNum3(
            (Number(this.tranForm.tran_insp_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
          );
          let num = new BigNumber(1);
          num = num
            .times(this.tranForm.tran_insp_list[i].prod_boxl ? this.tranForm.tran_insp_list[i].prod_boxl : 0)
            .times(this.tranForm.tran_insp_list[i].prod_boxw ? this.tranForm.tran_insp_list[i].prod_boxw : 0)
            .times(this.tranForm.tran_insp_list[i].prod_boxh ? this.tranForm.tran_insp_list[i].prod_boxh : 0)
            .div(1000000)
            .toNumber();
          this.tranForm.tran_insp_list[i].prod_vol = this.helper.calcNum3(num);
          this.tranForm.tran_insp_list[i].prod_tvol = this.helper.calcNum3(
            (Number(this.tranForm.tran_insp_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
          );
        }
      }
      //联动材料信息
      this.tranForm.tran_mtrb_list.forEach(item => {
        if (item.scon_prod_id === val) {
          //计算开票数量 = 出运数量 / 基数 * 耗量
          this.$set(item, 'inv_num', new BigNumber((Number(val1) / Number(item.prod_mtrb_per)) * Number(item.prod_mtrb_use)).toFixed(2));
          //计算开票金额
          this.$set(item, 'inv_money', parseFloat(new BigNumber(Number(item.inv_num)).times(Number(item.prod_mtrb_price)).toFixed(12)).toString());
        }
      });
      this.$refs.partDetailRef.$refs.PartDetail.updateData();
      this.$refs.inspDetailRef.$refs.InspDetail.updateData();
    },
    // 出运明细tran_prod_id变化
    newTranProdIdChange(val) {
      this.newTranProdId = val;
    },
    // 出运明细回显出运总金额 人民币金额
    prodTotalPriceChange(val) {
      let temp = JSON.parse(JSON.stringify(val));
      temp = Math.round(Number(temp) * 100) / 100;
      this.tranForm.tran_prtotal = this.helper.reservedDigits(temp);
      //人民币金额
      this.tranForm.tran_rmbtotal = new BigNumber(
        Number(this.tranForm.tran_usdrate ? this.tranForm.tran_usdrate : 0) * Number(this.tranForm.tran_prtotal)
      ).toFixed(4);
    },
    // 变更报关 商检 长宽高
    changeListLWH({ val, name, scon_prod_id }) {
      //为1是代表有复制的数据则不联动，直接跳出
      if (this.tranForm.is_linkage === 1) {
        return;
      }
      this.tranForm.tran_part_list.forEach((item, index) => {
        //主商品时联动长宽高
        if (Number(item.tran_part_main) === 1 || Number(item.tran_part_claim) === 1) {
          if (item.scon_prod_id === scon_prod_id) {
            item[`prod_box${name}`] = val;
            this.$refs.partDetailRef.calculateVolume(index);
          }
        }
      });
      this.tranForm.tran_insp_list.forEach((item, index) => {
        if (item.scon_prod_id === scon_prod_id) {
          item[`prod_box${name}`] = val;
          this.$refs.inspDetailRef.calculateVolume(index);
        }
      });
      this.$refs.partDetailRef.$refs.PartDetail.updateData();
      this.$refs.inspDetailRef.$refs.InspDetail.updateData();
    },
    //变更每箱毛重
    linkageGrossWeight(val, val2) {
      //为1是代表有复制的数据则不联动，直接跳出
      if (this.tranForm.is_linkage === 1) {
        return;
      }
      this.tranForm.tran_part_list.forEach((item, index) => {
        //主商品或报关时联动
        if (Number(item.tran_part_main) === 1 || Number(item.tran_part_claim) === 1) {
          if (item.scon_prod_id === val) {
            item.prod_gweight = val2;
            this.$refs.partDetailRef.totalGrossWeightChange(index);
          }
        }
      });
      this.tranForm.tran_insp_list.forEach((item, index) => {
        if (item.scon_prod_id === val) {
          item.prod_gweight = val2;
          this.$refs.inspDetailRef.totalGrossWeightChange(index);
        }
      });
      this.$refs.partDetailRef.$refs.PartDetail.updateData();
      this.$refs.inspDetailRef.$refs.InspDetail.updateData();
    },
    //变更每箱净重
    linkageNWeight(val, val2) {
      //为1是代表有复制的数据则不联动，直接跳出
      if (this.tranForm.is_linkage === 1) {
        return;
      }
      this.tranForm.tran_part_list.forEach((item, index) => {
        //主商品或报关时联动
        if (Number(item.tran_part_main) === 1 || Number(item.tran_part_claim) === 1) {
          if (item.scon_prod_id === val) {
            item.prod_nweight = val2;
            this.$refs.partDetailRef.totalNetWeight(index);
          }
        }
      });
      this.tranForm.tran_insp_list.forEach((item, index) => {
        if (item.scon_prod_id === val) {
          item.prod_nweight = val2;
          this.$refs.inspDetailRef.totalNetWeight(index);
        }
      });
      this.$refs.partDetailRef.$refs.PartDetail.updateData();
      this.$refs.inspDetailRef.$refs.InspDetail.updateData();
    },
    //变更每箱体积
    linkageTvol(val, val2) {
      //为1是代表有复制的数据则不联动，直接跳出
      if (this.tranForm.is_linkage === 1) {
        return;
      }
      this.tranForm.tran_part_list.forEach((item, index) => {
        //非主商品报关不联动
        if (Number(item.tran_part_main) === 1 || Number(item.tran_part_claim) === 1) {
          if (item.scon_prod_id === val) {
            item.prod_vol = helper.calcNum3(val2);
            this.$refs.partDetailRef.totalVolumeChange(index);
          }
        }
      });
      this.tranForm.tran_insp_list.forEach((item, index) => {
        if (item.scon_prod_id === val) {
          item.prod_vol = helper.calcNum3(val2);
          this.$refs.inspDetailRef.totalVolumeChange(index);
        }
      });
      this.$refs.partDetailRef.$refs.PartDetail.updateData();
      this.$refs.inspDetailRef.$refs.InspDetail.updateData();
    },
    // 出运明细销售合同号变化
    sconNoTypeListChange(val) {
      let list = val;
      let sconNoTypeListCopy = this.sconNoTypeList;
      let temp = [];
      for (let i = 0; i < sconNoTypeListCopy.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (sconNoTypeListCopy[i].label === list[j]) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        list.splice(i, 1);
      }
      for (let i = 0; i < list.length; i++) {
        let item = {};
        item.label = list[i];
        sconNoTypeListCopy.push(item);
      }
      // for (let i = 0; i < list.length; i++) {
      //   list[i].id = i;
      // }
      this.sconNoTypeList = sconNoTypeListCopy;
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.tranForm.tran_prod_list.every(item1 => item.tran_prod_id !== item1.tran_prod_id));
      this.tranForm.tran_prod_list = this.tranForm.tran_prod_list.concat(val);
      this.dialogVisible = false;
    },
    // // 计算费用总额
    totalExpenses(val) {
      this.tranForm.tran_fetotal = val;
      this.calcPrice();
    },
    // 回显金额
    feesTotalPriceChange(val) {
      this.tranForm.tran_fetotal = val;
    },
    //客户简称,通知人
    tranClauChange(row, val) {
      if (val === 2) {
        this.tranForm.inform_cust_id = row.cust_id; //通知人 客户id
        this.tranForm.inform_name = row.inform_name; //通知人
        this.tranForm.inform_site = row.inform_site; //通知人地址
        this.tranForm.cnee_name = row.cnee_name; //收货人
        this.tranForm.cnee_site = row.cnee_site; //收货人地址
      } else {
        this.tranForm.cust_bid = row.cust_id; //客户id
        this.tranForm.cust_babbr = row.cust_abbr; //客户简称
        this.tranForm.cust_bname = row.cust_name; // 客户全称
        this.tranForm.cust_bcontact = row.cust_contact; //客户联系人
        this.tranForm.cust_bphone = row.cust_phone; //客户联系人电话
        this.tranForm.cust_baddr = row.cust_addr; //客户地址
        get(cpttAPI.getCpttBankByCpttId, { cptt_id: row.cptt_aid })
          .then(res => {
            if (res.data.code === 0) {
              this.cptt_bank_list = res.data.data.list;
              return;
            }
            this.$message.error(res.data.msg);
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    //送货仓库
    tranClauChange2(row) {
      this.tranForm.inst_cid = row.id;
      this.tranForm.inst_cabbr = row.param1; //送货仓库
      this.tranForm.inst_ccontact = row.param4; //送货人
      this.tranForm.inst_ccphone = row.param3; //送货人电话
      this.tranForm.inst_caddr = row.param2; //送货人地址
    },
    //客户简称,通知人
    tranClauChange3(row, val) {
      if (val === 2) {
        this.tranForm.clau_bcontent += row.clau_content; //船公司联系
      } else {
        this.tranForm.clau_content = row.clau_content; //送货资料
      }
    },

    //货代简称
    importInstChange(row) {
      this.tranForm.inst_abbr = row.inst_abbr;
      this.tranForm.inst_id = row.inst_id;
      this.dialogInstVisible2 = false;
    },
    //客户订单号导入
    importSconCustChange(rows) {
      rows.forEach(item => {
        this.tranForm.scon_cust_nos += ',' + item.scon_cust_no;
        this.tranForm.scon_nos += ',' + item.scon_no;
      });
      this.dialogInstVisible3 = false;
    },
    //客户订单号选择
    sconCustSelect() {
      this.dialogInstVisible3 = true;
      setTimeout(() => {
        this.$refs.ImportSconCust.initData();
        this.$refs.ImportSconCust.notConfirmSelect();
      }, 300);
    },
    //外销经办人 改变 外销经办部门
    custStffChange(row) {
      let current = this.stffOptionList.filter(item => item.stff_id === row);
      this.tranForm.cust_dept_id = current[0].dept_id;
    },
    //获取银行信息
    getCpttBank(val) {
      get(cpttAPI.getCpttBankByCpttId, { cptt_id: this.tranForm.cptt_aid })
        .then(res => {
          if (res.data.code === 0) {
            this.cptt_bank_list = res.data.data.list;
            if (val === 1) {
              if (this.cptt_bank_list.length > 0) {
                this.tranForm.cptt_bank_name = this.cptt_bank_list[0].cptt_bank_name;
                this.tranForm.cptt_bank_account = this.cptt_bank_list[0].cptt_bank_account;
              }
            }
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    //获取客户信息
    getCust() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    // 获取单位
    getCompany() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.companyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取付款方式
    getCustPayway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取价格条款
    getCustPaylaw() {
      get(optnAPI.getOptnByPermId, { perm_id: 10014 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaylawList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取运输方式
    getCustShipway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10017 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShipwayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取运费支付方式
    getCustShippay() {
      get(optnAPI.getOptnByPermId, { perm_id: 10020 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShippayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取起运港
    getTranSport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.TranSportList = res.data.data.form.optn_cntt_list;
            // this.custDportChange();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取目的国
    getCustDcountry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDcountryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    delTmTableRow() {
      if (this.tmSeletion.length === 0) return this.$message.warning('请至少选择一条数据!');
      let { tableData, map } = deleteTableRow('modr_id', this.tranForm.tran_mtrb_list, this.tmTableMap, this.tmSeletion);
      this.tranForm.tran_mtrb_list = tableData;
      this.tmTableMap = map;
    },
    async getModrDialogData() {
      await (this.modrDialogFlag = true);
      let searchForm = this.$refs.modrDialog.searchForm;
      searchForm.scon_no = searchForm.scon_no ? searchForm.scon_no : this.tranForm.tran_prod_list[0].scon_no || null;
      searchForm.supp_ids = arrayDeRepeat(getArrayIds(this.tranForm.tran_prod_list, 'supp_id')).toString();
      await tranAPI.get_tran_import_mtrb_list(searchForm).then(({ data }) => {
        this.modrDialogTableData = data;
        this.modrDialogFlag = false;
      });
    },
    modrDialogDataRefresh() {
      this.$refs.modrDialog.resetFields();
      this.getModrDialogData();
    },
    modrDialogConfirm() {
      this.modrDialogSelection.forEach(item => {
        //有复制的数据不计算
        if (this.tranForm.tran_prod_list.filter(s => s.scon_prod_id === item.scon_prod_id).length === 1) {
          //计算开票数量 = 出运数量 / 基数 * 耗量
          let sconProd = this.tranForm.tran_prod_list.find(x => x.scon_prod_id === item.scon_prod_id);
          item.inv_num = new BigNumber((Number(sconProd.prod_ship) / Number(item.prod_mtrb_per)) * Number(item.prod_mtrb_use)).toFixed(2);
          //计算开票金额
          item.inv_money = parseFloat(new BigNumber(Number(item.inv_num)).times(Number(item.prod_mtrb_price)).toFixed(12)).toString();
        }
        this.tranForm.tran_mtrb_list.push(item);
      });
      this.importModrDialogVisible = false;
    },
    //计算所有子表key
    prodReductionKey() {
      this.$refs.prodDetailRef.reductionKey();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export3 {
  position: absolute;
  top: 0;
  left: 130px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_edhea {
  position: relative;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_dialog_o {
  /* width: 100%; */
  display: flex;
  justify-content: center;
}

table .vd_td_t {
  height: 30px;
  padding-left: 3px;
  border-bottom: none;
  border-left: none;
}

table .vd_td_o {
  width: 20%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}

.vd_borrn {
  border-right: none;
}

.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}

table {
  box-sizing: border-box;
  margin-top: 30px;
}

.vd_th {
  text-align: left;
  height: 30px;
  border-top: none;
  border-left: none;
  border-bottom: none;
  color: #909399;
  padding-left: 10px;
  box-sizing: border-box;
}

table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
</style>
