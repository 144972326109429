<template>
  <div class="ImportSconCust">
    <el-row>
      <el-col :md="24" style="margin: 5px">
        <el-button size="small" type="success" icon="el-icon-check" @click="confirmSelect()" class="vg_ml_16">确认选择 </el-button>
        <el-button size="small" type="danger" icon="el-icon-delete" @click="notConfirmSelect()" class="vg_ml_16"> 取消选择 </el-button>
        <el-button size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i>刷新 </el-button>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :tableRowKey="`podr_part_id`"
          :columns="tableProperties"
          v-loading="loading"
          :totalPage="total"
          @selection-change="handleSelectionChange"
          @getTableData="getCust"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get } from '@api/request';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tranAPI } from '@api/modules/tran';

export default {
  name: 'ImportSconCust',
  props: {
    tranForm: Object
  },
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1,
        scon_no: null, //外销合同编号
        scon_cust_no: null, //客户订单号
        cust_abbr: null //客户简称
      },
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        this.$store.state.index,
        { prop: 'scon_cust_no', label: '客户订单号', input: true, itemType: 'input', widthAuto: true },
        { prop: 'scon_no', label: '销售合同号', input: true, itemType: 'input', widthAuto: true },
        { prop: 'cust_abbr', label: '客户简称', input: false, itemType: 'input', widthAuto: true },
        { prop: 'cust_bid_abbr', label: '最终客户', input: false, itemType: 'input', widthAuto: true },
        {
          prop: 'scon_shdate',
          label: '装运日期',
          input: false,
          itemType: 'input',
          widthAuto: true,
          formatter: val => this.formatterTime(val)
        },
        { prop: 'cust_dport', label: '目的港', input: false, itemType: 'input', widthAuto: true }
      ],
      total: 0,
      tableData: [],
      selectTable: [],
      loading: false
    };
  },
  created() {
    // this.initData();
  },
  methods: {
    initData() {
      this.getCust();
    },
    //获取list接口
    getCust() {
      this.loading = true;
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.scon_cust_nos = this.tranForm.scon_cust_nos;
      searchForm.cust_abbr = this.tranForm.cust_abbr;
      get(tranAPI.getTranCusts, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.loading = false;
            return;
          }
          this.$message.error(res.data.msg);
          this.loading = false;
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 计算日期
    formatterTime(val) {
      return getDateNoTime(val, true);
    },
    //刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      // this.searchForm.page_no = 1;
      // this.$refs.pubPagination.currentPage = 1;
      this.getCust();
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTable = [];
      this.selectTable = val;
    },
    //查询
    getQutbNow() {
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCust();
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getCust();
    },
    getRowKey(row) {
      return row.scon_prod_id;
    },
    //确认选择
    confirmSelect() {
      this.$emit('importSconCustChange', this.selectTable);
    },
    //取消选择
    notConfirmSelect() {
      this.$refs.multiTable.clearSelection();
    }
  }
};
</script>

<style></style>
